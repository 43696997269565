@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin+Sketch&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
}

:root {
  --yellow: #ffc107;
  --blue: #112c85;
  --text: #4d4d4d;
  --red: #d9393a;
  --green: #81c14b;
  --light-text: #8c8c8c;
  --white: #ffffff;
}

.cursor-p {
  cursor: pointer;
}

.hoverThis {
  transition: 0.2s ease;
}
.hoverThis_active {
  background-color: var(--yellow);
  color: var(--white);
  border-color: var(--yellow) !important;
}
.hoverThis:hover {
  background-color: var(--yellow);
  color: var(--white);
  border-color: var(--yellow) !important;
}

.text-primary {
  color: var(--yellow) !important;
}
.bg-primary {
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
}
.text-blue {
  color: var(--blue) !important;
}
.bg-blue {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
}
.bg-red {
  background-color: var(--red) !important;
  border-color: var(--red) !important;
}
.text-red {
  color: var(--red) !important;
}
.text-green {
  color: var(--green) !important;
}
.bg-green {
  background-color: var(--green) !important;
  border-color: var(--green) !important;
}

.bg-glass {
  background: rgba(255, 255, 255, 0.445);
}

.hover-secondary:hover {
  background-color: rgb(236, 236, 236);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .bg-glass {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.Fw_500 {
  font-weight: 500 !important;
}
.Fw_600 {
  font-weight: 600 !important;
}

.Fw_400 {
  font-weight: 400 !important;
}

/* heellooooo */

/* hover classes */
.hoverShadow {
  transition: 0.3s ease !important;
}
.hoverShadow:hover {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}
.hoverShadow_active {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}
.hoverPrimary {
  transition: 0.3s ease;
}
.hoverPrimary:hover {
  color: var(--blue) !important;
  background-color: var(--yellow) !important;
}
.hoverPrimary_active {
  color: var(--blue);
  background-color: var(--yellow);
}

.HoverColorBlue:hover {
  color: var(--blue);
}

/* ::-webkit-scrollbar {
  width: 2px;
} */

::-webkit-scrollbar {
  display: none;
}

p {
  margin-bottom: 0 !important;
}
/* Track */
/* ::-webkit-scrollbar-track {
  background: #e0e0e0;

} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #ffee2d;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.topBarImg {
  min-width: 3rem;
  max-height: 3rem;
  max-width: 3rem;
  min-height: 3rem;
}

button.Dash_dropDownBtn.btn.btn-secondary {
  background: transparent !important;
  color: #4d4d4d !important;
  border-color: transparent !important;
  font-weight: 500 !important;
  box-shadow: none !important;
  border-radius: 0;
}
button.Dash_dropDownBtn.btn.btn-secondary:hover {
  background: rgb(17, 44, 133) !important;
  color: #e7e7e7 !important;
  border-color: transparent !important;
  font-weight: 500 !important;
  box-shadow: none !important;
  border-radius: 0;
}
button.Dash_dropDownBtn_Active.btn.btn-secondary {
  background: rgb(17, 44, 133) !important;
  color: #e7e7e7 !important;
  border-color: transparent !important;
  font-weight: 500 !important;
  box-shadow: none !important;
  border-radius: 0;
}

.AdjustWidthOfColumn {
  width: fit-content !important;
}

button.Dash_dropdown_item.dropdown-item {
  padding: 6px 12px !important;
  transition: all 0.1s ease-in-out;
}
button.Dash_dropdown_item.dropdown-item:hover {
  background-color: transparent !important;
  color: var(--yellow) !important;
}

.Dash_dropdown_Menu.dropdown-menu.show {
  border: none !important;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1) !important;
}
.headingBelowBorder {
  position: relative;
}

.headingBelowBorder::before {
  content: "";
  height: 3px;
  width: 60%;
  background-color: var(--yellow);
  position: absolute;
  bottom: 0;
  animation: BorderAnimation 1s ease-in-out;
}

@keyframes BorderAnimation {
  from {
    width: 0%;
  }
  to {
    width: 60%;
  }
}

.MainAttendenceReportForm {
  justify-self: center !important;
  align-self: center !important;
  display: flex;
  flex-direction: column;
}

.MainAttendenceReportForm {
  width: 75%;
}

.ReportFormWhole {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  gap: 1rem !important;
}
.ReportFormWhole > div {
  flex: 1 1 20rem;
  max-width: 100%;
}

.AttendenceNavBtn {
  display: flex;
  flex-wrap: wrap;
}

.AttendenceNavBtn > div {
  flex: 1 1 100px;
  max-width: 16rem;
  width: 16rem;
}

.TheAddOrderForm {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.TheAddOrderForm > div {
  flex: 1 1 20rem;
  max-height: 22rem;
}

@media (max-width: 992px) {
  .ReportFormWhole > div {
    flex: 1 1 20rem;
    max-width: 100%;
  }
  .MainAttendenceReportForm {
    width: 90%;
  }
  .AttendenceNavBtn > div {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.AddServiceMan_Main {
  display: flex;
  justify-content: center;
}

.TheServiceProviderForm {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.6rem;
}

.AddServieProvder_services {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.AddServieProvder_services > span {
  flex: 1 1 auto;
  max-width: fit-content;
  min-width: fit-content;
}

.Nav_hover {
  transition: 0.3s ease-in-out;
}
.Nav_hover:hover {
  background-color: var(--yellow);
  color: var(--blue);
  border-color: var(--yellow);
}
.Nav_hover_active {
  background-color: var(--yellow);
  color: var(--blue);
  border-color: var(--yellow);
}

.service-content{
  margin: auto;
    width: 50%;
    padding: 10px;
}

.service-page-footer {
  text-decoration: underline;
  cursor: pointer;
}


.text-danger strong {
        	color: #9f181c;
		}
		.receipt-main {
			background: #ffffff none repeat scroll 0 0;
			margin-top: 50px;
			padding: 40px 30px !important;
			position: relative;
			box-shadow: 0 1px 21px #acacac;
			font-family: open sans;
		}
		.receipt-main p {
			color: #333333;
			font-family: open sans;
			line-height: 1.42857;
		}
		.receipt-footer h1 {
			font-size: 15px;
			font-weight: 400 !important;
			margin: 0 !important;
		}
		/* .receipt-main::after {
			background: #414143 none repeat scroll 0 0;
			content: "";
			height: 5px;
			left: 0;
			position: absolute;
			right: 0;
			top: -13px;
		} */
		.receipt-main thead {
			background: #414143 none repeat scroll 0 0;
		}
	
		.receipt-right h5 {
			font-size: 16px;
			font-weight: bold;
			margin: 0 0 7px 0;
		}
		.receipt-right p {
			font-size: 12px;
			margin: 0px;
		}
		.receipt-right p i {
			text-align: center;
			width: 18px;
		}
		.receipt-main td {
			padding: 9px 20px !important;
		}
		.receipt-main th {
			padding: 13px 20px !important;
		}
		.receipt-main td {
			font-size: 13px;
			font-weight: initial !important;
		}
		.receipt-main td p:last-child {
			margin: 0;
			padding: 0;
		}	
		.receipt-main td h2 {
			font-size: 20px;
			font-weight: 900;
			margin: 0;
			text-transform: uppercase;
		}
		.receipt-header-mid .receipt-left h1 {
			font-weight: 100;
			margin: 34px 0 0;
			text-align: right;
			text-transform: uppercase;
		}
		.receipt-header-mid {
			margin: 24px 0;
			overflow: hidden;
		}
		
		#container {
			background-color: #dcdcdc;
		}
    
    .table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6 !important; /* Adjust the border color and width as needed */
}

.table-bordered tr {
  border: 1px solid #dee2e6 !important; /* Add border to table rows */
}

.fixed-width-name {
  width: 150px;
}

.fixed-width-data {
  width: 200px;
}