.header {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg,
      rgba(84, 58, 183, 1) 0%,
      rgba(0, 172, 193, 1) 100%);
  color: white;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }
}

.running-cell {
  background-color: #f1c40f !important;
  color: #ffff;
}

.complete-cell {
  background-color: #27ae60 !important;
  color: #ffff;
}

.cancel-cell {
  background-color: #95a5a6 !important;
  color: #ffff;
}

.hold-cell {
  background-color: #e74c3c !important;
  color: #ffff;
}

.due-cell {
  background-color: #a29bfe !important;
  color: #ffff;
}

.pending-cell {
  background-color: #e67e22 !important;
  color: #ffff;
}

.class-green {
  background-color: #27ae60 !important;
  color: #ffff;
  margin: 1px 1px 1px 0px !important;
}

.class-green2 {
  background-color: #27ae60 !important;
  color: #ffff;
  border-bottom: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important;
  /* margin: 1px 1px 1px 0px !important; */
}
.class-red2 {
  background-color: #f20606 !important;
  color: #ffff;
  border-bottom: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important
}

.Cancel-availability2{
  background-color: #95a5a6 !important;
  color: black !important;
  border-bottom: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important
}

.completed-cell2 {
  background-color: green !important;
  color: black !important;
  border-bottom: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important
}

.Running-availability2{
  background-color: #f1c40f !important;
  color: black !important;
  border-bottom: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important
}
.class-monthly2{
  background-color: #74b9ff !important;
  color: black !important;
  border-bottom: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important
}


.class-yellow {
  background-color: #e67e22 !important;
  color: #ffff;
  margin: 1px 1px 1px 0px !important;
}
.class-red {
  background-color: #ff1900 !important;
  color: #ffff;
  margin: 1px 1px 1px 0px !important;
}

.css-wop1k0-MuiDataGrid-footerContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 35px;
  border-top: 1px solid;
}


.activeNavItem {
  color: var(--blue) !important;
  background-color: var(--yellow) !important;
}



.Due-availability2{
  background-color: #a29bfe !important;
  color: black !important;
  border-bottom: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important
}

.Hold-availability2{
  background-color: #f10000;
  color: black !important;
  border-bottom: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important
}

.Pending-availability2{
  background-color: #e67e22 !important;
  color: black !important;
  border-bottom: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important
}
